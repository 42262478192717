<template>
	<div>
		<div v-for="(item,index) in search" :key="item.id" class="cup-item" @click="clkCup(item.id)">
			<div class="cup-icon">
				<p class="p-icon">
					<i class="iconfont">&#xe605;</i>
				</p>
				<p style="display: -webkit-box;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 1;">{{item.title}}</p>
			</div>
			<p style="flex-shrink: 0;padding-left: 20px;">{{time(item.inputtime)}}</p>
		</div>
	</div>
</template>

<script>
	import moment from "moment"
	export default {
		props:{
			search:{
				type:Array,
				default:()=>[]
			}
		},
		methods:{
			clkCup(id) {
				this.$emit("clkCup",id)
			}
		},
		computed:{
			time() {
				return (val) =>{
					let time = moment(val*1000).format("YYYY-MM-DD")
					return time
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.cup-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 400;
		padding: 20px 0;
		border-bottom: 1px solid rgba(235, 236, 239, 1);
		cursor: pointer;
		&:hover {
			color: var(--themeColor);
			background-color: #f5f7fa;
		}
		
		.cup-icon {
			display: flex;
			align-items: center;
			.p-icon {
				width: 26px;
				height: 26px;
				background: rgba(10, 72, 144, 0.08);
				border-radius: 50%;
				text-align: center;
				line-height: 26px;
				margin-right: 8px;
				.iconfont {
					color: var(--themeColor);
				}
			}
			
		}
	}
	@media (max-width:900px) {
		.cup-item:first-child {
			padding-top: 20px;
		}
	}
</style>