<template>
	<div>
		<navs navTag="search"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="['首页','搜索']"></crumbs>
			<div class="cotain">
				<div class="search">
					<el-input placeholder="请输入内容" v-model="keywords">
						<el-button slot="append" icon="el-icon-search" @click="clkSearch">搜 索</el-button>
					</el-input>
				</div>
				<div>
					<template v-if="!allData">
						<el-empty description="暂无数据"></el-empty>
					</template>
					<template v-else>
						<div v-if="search.djyw.length" class="search">
							<tap :taps="['党的建设']"><i></i></tap>
							<consea :search="search.djyw" @clkCup="clkDjyw"></consea>
						</div>
						<div v-if="search.hdbm.length" class="search">
							<tap :taps="['活动报名']"><i></i></tap>
							<consea :search="search.hdbm" @clkCup="clkHdbm"></consea>
						</div>
						<div v-if="search.hyfw.length" class="search">
							<tap :taps="['会员服务']"><i></i></tap>
							<consea :search="search.hyfw" @clkCup="clkHyfw"></consea>
						</div>
						<div v-if="search.jy.length" class="search">
							<tap :taps="['教育活动']"><i></i></tap>
							<consea :search="search.jy" @clkCup="clkDjyw"></consea>
						</div>
						<div v-if="search.jygg.length" class="search">
							<tap :taps="['交易公告']"><i></i></tap>
							<consea :search="search.jygg" @clkCup="clkJygg"></consea>
						</div>
						<div v-if="search.jyzd.length" class="search">
							<tap :taps="['交易制度']"><i></i></tap>
							<consea :search="search.jyzd" @clkCup="clkJyzd"></consea>
						</div>
						<div v-if="search.pxly.length" class="search">
							<tap :taps="['培训路演']"><i></i></tap>
							<consea :search="search.pxly" @clkCup="clkPxly"></consea>
						</div>
						
						<div v-if="search.ttzjy.length" class="search">
							<tap :taps="['投资者教育']"><i></i></tap>
							<consea :search="search.ttzjy" @clkCup="clkTzzjy"></consea>
						</div>
						
						<div v-if="search.xwgg.length" class="search">
							<tap :taps="['新闻公告']"><i></i></tap>
							<consea :search="search.xwgg" @clkCup="clkXwgg"></consea>
						</div>
						<div v-if="search.xxpl.length" class="search">
							<tap :taps="['信息披露']"><i></i></tap>
							<consea :search="search.xxpl" @clkCup="clkXxpl"></consea>
						</div>
						
						<div v-if="search.xxplgg.length" class="search">
							<tap :taps="['信息披露公告']"><i></i></tap>
							<consea :search="search.xxplgg" @clkCup="clkXxpl"></consea>
						</div>
						<div v-if="search.zczq.length" class="search">
							<tap :taps="['政策专区']"><i></i></tap>
							<consea :search="search.zczq" @clkCup="clkZczq"></consea>
						</div>
						<div v-if="search.zdxm.length" class="search">
							<tap :taps="['重点项目推荐']"><i></i></tap>
							<consea :search="search.zdxm" @clkCup="clkZdxm"></consea>
						</div>
						<div v-if="search.zlxz.length" class="search">
							<tap :taps="['资料下载']"><i></i></tap>
							<div v-for="item in search.zlxz" class="down">
								<div class="title">{{item.title}}</div>
								<div class="dowm-txt">
									<div>{{time(item.inputtime)}}</div>
									<div class="xzz" @click="clkDown(item.file)">下载</div>
								</div>
							</div>
						</div>
						<div v-if="search.zzbk_qyzq.length" class="search">
							<tap :taps="['专精板块（企业专区）']"><i></i></tap>
							<consea :search="search.zzbk_qyzq" @clkCup="clkZjQyzq"></consea>
						</div>
						<div v-if="search.zzbk_cpfw.length" class="search">
							<tap :taps="['专精板块（专享产品服务）']"><i></i></tap>
							<consea :search="search.zzbk_cpfw" @clkCup="clkZjQyzq2"></consea>
						</div>
						<div v-if="search.zzbk_xwzx.length" class="search">
							<tap :taps="['专精板块（新闻中心）']"><i></i></tap>
							<consea :search="search.zzbk_xwzx" @clkCup="clkZjQyzq3"></consea>
						</div>
						<div v-if="search.zzbk_xzzq.length" class="search">
							<tap :taps="['专精板块（下载专区）']"><i></i></tap>
							<div v-for="item in search.zzbk_xzzq" class="down">
								<div class="title">{{item.title}}</div>
								<div class="dowm-txt">
									<div>{{time(item.inputtime)}}</div>
									<div class="xzz" @click="clkDown(item.file)">下载</div>
								</div>
							</div>
						</div>
						<div v-if="search.kjcx_qyzq.length" class="search">
							<tap :taps="['科技创新专板（企业专区）']"><i></i></tap>
							<consea :search="search.kjcx_qyzq" @clkCup="clkZjQyzq4"></consea>
						</div>
						<div v-if="search.kjcx_cpfw.length" class="search">
							<tap :taps="['科技创新专板（专享产品服务）']"><i></i></tap>
							<consea :search="search.kjcx_cpfw" @clkCup="clkZjQyzq5"></consea>
						</div>
						<div v-if="search.kjcx_xwzx.length" class="search">
							<tap :taps="['科技创新专板（新闻中心）']"><i></i></tap>
							<consea :search="search.kjcx_xwzx" @clkCup="clkZjQyzq6"></consea>
						</div>
						
						<div v-if="search.kjcx_xzzq.length" class="search">
							<tap :taps="['科技创新专板（下载专区）']"><i></i></tap>
							<div v-for="item in search.kjcx_xzzq.zlxz" class="down">
								<div class="title">{{item.title}}</div>
								<div class="dowm-txt">
									<div>{{time(item.inputtime)}}</div>
									<div class="xzz" @click="clkDown(item.file)">下载</div>
								</div>
							</div>
						</div>
						
						<div v-if="search.jyb_qyzq.length" class="search">
							<tap :taps="['交易版（企业专区）']"><i></i></tap>
							<consea :search="search.jyb_qyzq" @clkCup="clkZjQyzq7"></consea>
						</div>
						<div v-if="search.jyb_cpfw.length" class="search">
							<tap :taps="['交易版（专享产品服务）']"><i></i></tap>
							<consea :search="search.jyb_cpfw" @clkCup="clkZjQyzq8"></consea>
						</div>
						<div v-if="search.jyb_xwzx.length" class="search">
							<tap :taps="['交易版（新闻中心）']"><i></i></tap>
							<consea :search="search.jyb_xwzx" @clkCup="clkZjQyzq9"></consea>
						</div>
						<div v-if="search.jyb_xzzq.length" class="search">
							<tap :taps="['交易版（下载专区）']"><i></i></tap>
							<div v-for="item in search.jyb_xzzq.zlxz" class="down">
								<div class="title">{{item.title}}</div>
								<div class="dowm-txt">
									<div>{{time(item.inputtime)}}</div>
									<div class="xzz" @click="clkDown(item.file)">下载</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import consea from "./consea.vue"
	import moment from "moment"
	export default {
		data() {
			return {
				search:{},
				keywords:"",
				allData:false // 假设都没有数据
			}
		},
		components:{
			consea
		},
		computed:{
			time() {
				return (val) =>{
					let time = moment(val*1000).format("YYYY-MM-DD")
					return time
				}
			}
		},
		created() {
			if(sessionStorage.getItem("keywords")) {
				this.keywords = sessionStorage.getItem("keywords")
				this.$axios.get("Base/search",{
					params:{
						keywords:this.keywords
					}
				}).then(res=>{
					this.search = res.data
					for(let key in res.data) {
						if(res.data[key].length!=0) {
							this.allData = true
						}
					}
				})
			}
		},
		methods:{
			clkSearch() {
				sessionStorage.setItem("keywords", this.keywords);
				this.$axios.get("Base/search",{
					params:{
						keywords:this.keywords
					}
				}).then(res=>{
					this.search = res.data
					
					for(let key in res.data) {
						if(res.data[key].length!=0) {
							this.allData = true
						}
					}
				})
			},
			// 点击党的建设
			clkDjyw(id) {
				this.$router.push({"path":"newdetail",query:{index:4,id}})
			},
			// 点击活动报名
			clkHdbm(id) {
				this.$router.push({"path":"/ecation/ather",query:{index:2,id,left_id:44}})
			},
			// 点击会员服务
			clkHyfw(id) {
				this.$router.push({path:"/member/shdetail",query:{id}})
			},
			// 点击交易公告
			clkJygg(id) {
				this.$router.push({path:"/trading/annodetail",query:{id}})
			},
			// 点击交易制度
			clkJyzd(id) {
				this.$router.push({path:"/system/system_details",query:{id}})
			},
			// 点击培训路演
			clkPxly(id) {
				let queryPath = "Policyzone/GetTrainDetails"
				this.$router.push({path:"/train/tadetail",query:{index:1,id,queryPath}})
			},
			// 点击投资者教育
			clkTzzjy(id) {
				
			},
			// 点击新闻公告
			clkXwgg(id) {
				this.$router.push({path:"newdetails",query:{index:5,id}})
			},
			// 点击信息披露
			clkXxpl(id) {
				this.$router.push({path:"/infotion/details",query:{id}})
			},
			// 点击政策专区
			clkZczq(id) {
				this.$router.push({path:"/policy/poldeal",query:{id}})
			},
			// 点击重点项目
			clkZdxm(id) {
				this.$router.push({path:"/promote/promote_details",query:{id}})
			},
			
			// 点击专精板块企业专区
			clkZjQyzq(id) {
				this.$router.push({path:"areaDail",query:{type:1,id}})
			},
			// 点击专精板块共享服务
			clkZjQyzq2(id) {
				// scGpDails?id=16&type=1
				this.$router.push({path:"scGpDails",query:{id,type:1}})
			},
			// 点击专精模块新闻中心
			clkZjQyzq3(id) {
				this.$router.push({path:"scNewsdeal",query:{type:1,id}})
			},
			
			// 点击专精板块企业专区
			clkZjQyzq4(id) {
				this.$router.push({path:"areaDail",query:{type:2,id}})
			},
			// 点击专精板块共享服务
			clkZjQyzq5(id) {
				this.$router.push({path:"scGpDails",query:{id,type:2}})
			},
			// 点击专精模块新闻中心
			clkZjQyzq6(id) {
				this.$router.push({path:"scNewsdeal",query:{type:2,id}})
			},
			
			
			// 点击专精板块企业专区
			clkZjQyzq7(id) {
				this.$router.push({path:"areaDail",query:{type:3,id}})
			},
			// 点击专精板块共享服务
			clkZjQyzq8(id) {
				this.$router.push({path:"scGpDails",query:{id,type:3}})
			},
			// 点击专精模块新闻中心
			clkZjQyzq9(id) {
				this.$router.push({path:"scNewsdeal",query:{type:3,id}})
			},
			
			
			// 点击下载
			clkDown(file) {
				window.location.href = file
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .not-tap {
		margin-bottom: 0;
	}
	/deep/ .not-tap::after {
		height: 1px;
	}
	.down {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 20px 0;
		border-bottom: 1px solid #ebecef;
		color: #666666;
		font-size: 16px;
		font-weight: 400;
		.dowm-txt {
			display: flex;
			align-items: center;
			.xzz {
				font-size: 16px;
				color: var(--themeColor);
				margin-left: 10px;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
				
			}
		}
	}
	.region {
		.cotain {
			margin-bottom: 60px;
			background-color: #fff;
			box-sizing: border-box;
			padding: 30px;
			.search {
				margin-bottom: 30px;
			}
		}
	}
</style>